body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.active{
  background-color: white;
  color:red !important;
}
.errorFormTeon{
  font-size: 10px;
  color: red;
}
.inputFormTeon {
  min-height: 70px;
}
.inputFormSuscribeTeon{
  min-height: 70px;
  display: inline-block;
}
.inputSuscribe{
  border-radius: 10px 0 0 10px;
  border: 3px solid white;
  font-size: 1rem;
  min-height: 44px;
  padding-left: 10px;
}
.inputSuscribe::placeholder{
  font-size: 1rem;
  padding-left: 20px;
}
