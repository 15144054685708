.root {
  background: linear-gradient(145deg, #e30513 100%, white 5%);
  color: white;
  height: 100vh;
  width: 100vw;
}
.container {
  width: 100%;
  height: 75vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.containerSecond {
  display: flex;
  align-items: center;
}
.titleStyle {
  font-size: 40px;
  color: #d4d6d4;
  margin-bottom: 20px;
  font-family: "Bouchers";
  /* padding-left:60px; */
  text-align:center;
}
.contentStyle {
  font-size: 35px;
  font-weight: bold;
  font-family: "Lato";
}
.dividerStyle {
  background: #d4d6d4 !important;
  height: 3px !important;
  width: 25%;
  margin-bottom: 20px !important;
}
.subtitle {
  font-size: 18px;
  color: #d4d6d4;
  font-family: "Lato";
}
.dateContainer {
  transform: rotate(-90deg);
  width: 150px;
  position: fixed;
  left: -25px;
  margin-top: 22%;
}
@media only screen and (max-width: 600px) {
  .dateContainer {
    transform: rotate(-90deg);
    width: 150px;
    position: fixed;
    left: -40px;
    margin-top: 50%;
  };
  .titleStyle {
 
  };
}

.dateText {
  font-size: 18px;
  color: #d4d6d4;
  font-family: "Lato";
}



