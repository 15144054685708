.App {
  text-align: center;
  /*background-color: #282c34;*/
  font-size: calc(10px + 2vmin);
  /*color: white;*/
  min-height: 100vh;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  min-height: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@font-face {
  font-family: "Lato";
  src: url("assets/lato.light.ttf") format("truetype");
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: "Lato";
  src: url("assets/lato.bold.ttf") format("truetype");
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: "Bouchers";
  src: url("assets/BouchersScript2.ttf") format("truetype");
  font-style: normal;
  font-weight: 300;
}
